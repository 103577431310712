// console.log('Hello from contests.js')

$(document).ready(function() {
  $('input[type=radio][name="contest[multiple_rounds]"]').change(function() {
    var url = "/admin/contests/load_round_options?multi="+$(this).val() 

    // example of retrieving values from the form
    // var id = $('#contest_id').val();
    // console.log(id)
    
    $.ajax({
        url: url,
        success: function(data) {
            // console.log('success')
        },
        error: function() {
            console.log('failure')
        }
    });    
  });
  
  // $('#round-drop-down').change(function() {
  //   console.log($(this).val())
  // });
  
  $('input[type=radio][name="contest[league_id]"]').change(function() {
    var url = "/admin/contests/load_season_options?league_id="+$(this).val() 

    $.ajax({
        url: url,
        success: function(data) {
            // console.log('success')
        },
        error: function() {
            console.log('failure')
        }
    });    
  });

  $('input[type=radio][name="contest[season_id]"]').change(function() {
    var url = "/admin/contests/load_round_frequency_options?season_id="+$(this).val() 

    $.ajax({
        url: url,
        success: function(data) {
            // console.log('success')
        },
        error: function() {
            console.log('failure')
        }
    });  
  });
  

});


// $(document).ready(function() {
//
//   $('#round-drop-down').change(function() {
//     console.log('Wo 2!')
//   });
//
// });